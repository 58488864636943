import { template as template_06f57147fef7495c993ecc4cee1291e6 } from "@ember/template-compiler";
const WelcomeHeader = template_06f57147fef7495c993ecc4cee1291e6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
