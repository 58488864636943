import { template as template_f950661296b84f41bcb0b6dc7aa8f18a } from "@ember/template-compiler";
const FKLabel = template_f950661296b84f41bcb0b6dc7aa8f18a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
