import { template as template_4844af2d0de043e2b509999da9d76917 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_4844af2d0de043e2b509999da9d76917(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
